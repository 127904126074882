import React from 'react';
import { Button, ButtonProps } from '../../../Button/button.component';

export interface ModalExpandButtonProps extends ButtonProps {
  isExpanded: boolean;
}

export const ModalExpandButton = ({
  right = 12,
  top = 2,
  size = 'md',
  isExpanded,
  ...rest
}: ModalExpandButtonProps): JSX.Element => {
  return (
    <Button
      display={{ md: 'flex', base: 'none' }}
      variant="ghostNeutral"
      iconName={isExpanded ? 'arrowsIn' : 'arrowsOut'}
      position="absolute"
      right={right}
      top={top}
      size={size}
      {...rest}
    />
  );
};
