import React, { ReactNode } from 'react';
import {
  Modal as CModal,
  ModalProps as CModalProps,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import { ModalSize } from './modal.types';

type ResponsiveBreakpoints = 'base' | 'md';

export interface ModalProps extends CModalProps {
  children: ReactNode;
  isCentered?: boolean;
  responsiveModalSizes?: Partial<Record<ResponsiveBreakpoints, ModalSize>>;
  allowEscape?: boolean;
  useFullScreenMobile?: boolean;
  initialFocusRef?: React.RefObject<FocusableElement>;
  scrollBehavior?: 'inside' | 'outside';
  size?: string;
  isExpanded?: boolean;
}

export const Modal = ({
  children,
  isOpen,
  onClose,
  isCentered = true,
  responsiveModalSizes,
  allowEscape = true,
  useFullScreenMobile = true,
  initialFocusRef,
  scrollBehavior,
  size = 'xl',
  isExpanded = false,
  ...rest
}: ModalProps): JSX.Element => {
  // Responsive values for full screen modals on phones.
  const fullScreenMobileSize = useBreakpointValue({ base: 'full', md: size });
  const fullScreenMobileBorderRadius = useBreakpointValue({ base: 0, md: 'xl' });
  const modalMargin = useBreakpointValue({ base: useFullScreenMobile ? 0 : 16, md: 16 });

  const Modal: JSX.Element = (
    <CModal
      closeOnEsc={allowEscape}
      closeOnOverlayClick={allowEscape}
      autoFocus={!!initialFocusRef}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={scrollBehavior}
      isCentered={isCentered}
      size={useFullScreenMobile || isExpanded ? fullScreenMobileSize : size}
      motionPreset="slideInBottom"
      {...rest}
    >
      <ModalOverlay
        bg={isExpanded ? 'neutral.100' : 'blackAlpha.600'}
        style={{ transition: 'background-color 0.7s ease-in-out' }}
      />
      <ModalContent
        backgroundColor="background.overlay"
        m={modalMargin}
        {...(!useFullScreenMobile && { mx: 5 })}
        borderRadius={isExpanded ? 'none' : useFullScreenMobile ? fullScreenMobileBorderRadius : 'xl'}
        minH={{ md: isExpanded ? '100%' : '100px', ...(useFullScreenMobile && { base: '-webkit-fill-available' }) }}
        minW={{ md: isExpanded ? '650px' : '300px' }}
        maxH={scrollBehavior === 'inside' ? '100%' : undefined}
        transition="min-height 0.7s ease-in-out, min-width 0.7s ease-in-out, border-radius 0.7s ease-in-out"
      >
        {children}
      </ModalContent>
    </CModal>
  );

  return Modal;
};
