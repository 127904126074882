import React, {
  ReactNode,
  useState,
  useContext,
  createContext,
  useRef,
  Dispatch,
  SetStateAction,
  MutableRefObject,
  useCallback,
  useEffect,
} from 'react';
import { MenuItemProps } from '../components/MenuItem/menuItem.component';

export interface IMenuContext {
  allMenuItems?: MenuItemProps[];
  filteredMenuItems?: MenuItemProps[];
  focusedItemIndex: number;
  groups: (string | undefined)[] | null;
  menuItemRefs?: MutableRefObject<HTMLDivElement[] | null[]>;
  handleOnClose: () => void;
  searchInputRef?: MutableRefObject<HTMLInputElement | null>;
  setFilteredMenuItems: Dispatch<SetStateAction<MenuItemProps[]>>;
  setFocusedItemIndex: Dispatch<SetStateAction<number>>;
  setGroups: Dispatch<SetStateAction<(string | undefined)[] | null>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
}

// TODO: Figure out how to document this functionality

export const MenuContext = createContext<IMenuContext>({
  allMenuItems: [],
  filteredMenuItems: [],
  focusedItemIndex: -1,
  groups: [],
  searchText: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleOnClose: (): Promise<void> => {
    throw new Error('handleOnClose(): Promise<void> not implemented');
  },
  setFilteredMenuItems: (): Promise<void> => {
    throw new Error('setFilteredMenuItems(): Promise<void> not implemented');
  },
  setFocusedItemIndex: (): Promise<void> => {
    throw new Error('setFocusedItemIndex(): Promise<void> not implemented');
  },
  setGroups: (): Promise<void> => {
    throw new Error('setGroups(): Promise<void> not implemented');
  },
  setSearchText: (): Promise<void> => {
    throw new Error('setSearchText(): Promise<void> not implemented');
  },
});

export const useMenuContext = (): IMenuContext => useContext<IMenuContext>(MenuContext);

export interface MenuProviderProps {
  menuItems?: MenuItemProps[];
  onClose?: (value: (MenuItemProps | undefined)[]) => void;
  isMultiSelect?: boolean;
  children?: ReactNode;
}

export const MenuProvider = ({ menuItems, onClose, children }: MenuProviderProps): JSX.Element => {
  const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItemProps[]>(menuItems || []);
  const [groups, setGroups] = useState<(string | undefined)[] | null>(null);
  const [focusedItemIndex, setFocusedItemIndex] = useState<number>(-1);
  const [searchText, setSearchText] = useState<string>('');

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const menuItemRefs = useRef<HTMLDivElement[] | null[]>([]);

  const handleOnClose = useCallback(() => {
    const selectedItems = menuItems?.filter((item) => item.isSelected === true);
    setFocusedItemIndex(-1);
    setSearchText('');
    setTimeout(() => {
      setFilteredMenuItems(menuItems || []);
    }, 200);

    if (onClose && selectedItems) {
      onClose(selectedItems);
    }
  }, [menuItems, onClose]);

  useEffect(() => {
    if (menuItems?.length) {
      setFilteredMenuItems(menuItems);
    }
  }, [menuItems]);

  return (
    <MenuContext.Provider
      value={{
        allMenuItems: menuItems,
        filteredMenuItems,
        focusedItemIndex,
        groups,
        menuItemRefs,
        handleOnClose,
        searchInputRef,
        setFilteredMenuItems,
        setFocusedItemIndex,
        setGroups,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
